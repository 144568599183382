import React from 'react';

import { Helmet } from 'react-helmet';
import { MemoryRouter, Routes, Route } from 'react-router-dom';
import {Link} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import { LinkContainer } from 'react-router-bootstrap';

import './App.css';
const Home = () => <span>Home</span>;

const About = () => <span>About</span>;

const Users = () => <span>Users</span>;

const App = () => (
  <div className="App">
      <header className="App-header">
      <Helmet>
        <h1>William Reusing | </h1>
      </Helmet>

      </header>

  <div className="menu">
      <ul>
        <li> <Link to="/">Home</Link> </li>
        <li> <Link to="/contact">Contact</Link> </li>
        <li> <Link to="/about">About</Link> </li>
      </ul>
  </div>
  <p>
  <div class='Title'>  William Reusing</div>
  </p>
  <a
    className="App-link"
    href="https://reactjs.org/"
    target="_blank"
    rel="noopener noreferrer">
    made using reactjs
  </a>
  </div>
);

export default App;
